<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A balloon that is filled with gas was released at ground level. At ground level where the
        pressure was
        <latex-number :number="Pressure1.toFixed(1)" unit="\text{mmHg}" />
        and the temperature was
        <number-value :value="Temperature1" unit="^\circ\text{C}" />
        the balloon had a volume of
        <number-value :value="Volume1" unit="\text{L.}" />
        As the balloon floated higher in the atmosphere, it reached an altitude where the pressure
        and temperature were
        <latex-number :number="Pressure2.toFixed(1)" unit="\text{mmHg}" />
        and
        <number-value :value="Temperature2" unit="^\circ\text{C,}" />
        respectively. Determine the <b>volume</b> (in L) of the balloon at this new altitude.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question64',
  components: {
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    Pressure1() {
      return this.taskState.getValueBySymbol('Pressure1').content.toFloat();
    },
    Pressure2() {
      return this.taskState.getValueBySymbol('Pressure2').content.toFloat();
    },
    Temperature1() {
      return this.taskState.getValueBySymbol('Temperature1').content;
    },
    Temperature2() {
      return this.taskState.getValueBySymbol('Temperature2').content;
    },
    Volume1() {
      return this.taskState.getValueBySymbol('Volume1').content;
    },
  },
};
</script>
